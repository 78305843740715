

# out=[];str='aaaaa';for(i=0;i<str.length;i++){out.push('\\u'+ str.charCodeAt(i).toString(16).padStart(4,'0'))};out.join('')


replacements =
  superscript:
    abc: "\u1d43\u1d47\u1d9c\u1d48\u1d49\u1da0\u1d4d\u02b0\u1da6\u02b2\u1d4f\u02e1\u1d50\u207f\u1d52\u1d56\u146b\u02b3\u02e2\u1d57\u1d58\u1d5b\u02b7\u02e3\u02b8\u1dbb"
    ABC: "\u1d2c\u1d2e\u1d9c\u1d30\u1d31\u1da0\u1d33\u1d34\u1d35\u1d36\u1d37\u1d38\u1d39\u1d3a\u1d3c\u1d3e\u0051\u1d3f\u02e2\u1d40\u1d41\u2c7d\u1d42\u02e3\u02b8\u1dbb"
    digits: "\u2070\u00b9\u00b2\u00b3\u2074\u2075\u2076\u2077\u2078\u2079"
    "*": "\ufe61"
    ",": "\ufe50"
    ".": "\u22c1"#"\u2219"#"\ufe52"
    ";": "\ufe54"
    ":": "\ufe55"
    "?": "\ufe56"
    "!": "\ufe57"
    "-": "\ufe58"
    "(": "\u207d"
    ")": "\u207e"
    "{": "\ufe5b"
    "}": "\ufe5c"
    "[": "\ufe5d"
    "]": "\ufe5e"
    "#": "\ufe5f"
    "&": "\ufe60"
    "*": "\ufe61"
    "+": "\u207a"
    "-": "\u207b"
    "<": "\ufe64"
    ">": "\ufe65"
    "=": "\u207c"
    "\\": "\ufe68"
    "$": "\ufe69"
    "%": "\ufe6a"
    "@": "\ufe6b"
    "_": "\u208b"


  upsidedown:
    abc: "\u0042\u0254\u0070\u01dd\u025f\u0183\u0265\u0131\u027e\u029e\u05df\u026f\u0075\u006f\u0064\u0062\u0279\u0073\u0287\u006e\u028c\u028d\u0078\u028e\u007a"
    ABC: "\u0250\u2200\u0071\u03fd\u15e1\u018e\u2132\u0183\u0048\u0049\u017f\u029e\u02e5\u0057\u004e\u004f\u0500\u1f49\u1d1a\u0053\u22a5\u2229\u039b\u004d\u0058\u028e\u005a"
    digits: ""
    ',':'\u0060'
    '.':'\u02d9'
    '!': '\u00bf'
    '?': '\u00a1'
    '&': '\u214b'

  bold:
    abc: "\ud835\udc1a\ud835\udc1b\ud835\udc1c\ud835\udc1d\ud835\udc1e\ud835\udc1f\ud835\udc20\ud835\udc21\ud835\udc22\ud835\udc23\ud835\udc24\ud835\udc25\ud835\udc26\ud835\udc27\ud835\udc28\ud835\udc2a\ud835\udc2b\ud835\udc2c\ud835\udc2d\ud835\udc2e\ud835\udc2f\ud835\udc30\ud835\udc31\ud835\udc32\ud835\udc33"
    ABC: "\ud835\udc00\ud835\udc01\ud835\udc02\ud835\udc03\ud835\udc04\ud835\udc05\ud835\udc06\ud835\udc07\ud835\udc08\ud835\udc09\ud835\udc0a\ud835\udc0b\ud835\udc0c\ud835\udc0d\ud835\udc0e\ud835\udc10\ud835\udc11\ud835\udc12\ud835\udc13\ud835\udc14\ud835\udc15\ud835\udc16\ud835\udc17\ud835\udc18\ud835\udc19"
    digits: "\ud835\udfce\ud835\udfcf\ud835\udfd0\ud835\udfd1\ud835\udfd2\ud835\udfd3\ud835\udfd4\ud835\udfd5\ud835\udfd6\ud835\udfd7"

  italic:
    abc: "\ud835\udc4e\ud835\udc4f\ud835\udc50\ud835\udc51\ud835\udc52\ud835\udc53\ud835\udc54\u210e\ud835\udc56\ud835\udc57\ud835\udc58\ud835\udc59\ud835\udc5a\ud835\udc5b\ud835\udc5c\ud835\udc5e\ud835\udc5f\ud835\udc60\ud835\udc61\ud835\udc62\ud835\udc63\ud835\udc64\ud835\udc65\ud835\udc66\ud835\udc67"
    ABC: "\ud835\udc34\ud835\udc35\ud835\udc36\ud835\udc37\ud835\udc38\ud835\udc39\ud835\udc3a\ud835\udc3b\ud835\udc3c\ud835\udc3d\ud835\udc3e\ud835\udc3f\ud835\udc40\ud835\udc41\ud835\udc42\ud835\udc44\ud835\udc45\ud835\udc46\ud835\udc47\ud835\udc48\ud835\udc49\ud835\udc4a\ud835\udc4b\ud835\udc4c\ud835\udc4d"

  bold_italic:
    abc: "\ud835\udc82\ud835\udc83\ud835\udc84\ud835\udc85\ud835\udc86\ud835\udc87\ud835\udc88\ud835\udc89\ud835\udc8a\ud835\udc8b\ud835\udc8c\ud835\udc8d\ud835\udc8e\ud835\udc8f\ud835\udc90\ud835\udc92\ud835\udc93\ud835\udc94\ud835\udc95\ud835\udc96\ud835\udc97\ud835\udc98\ud835\udc99\ud835\udc9a\ud835\udc9b"
    ABC: "\ud835\udc68\ud835\udc69\ud835\udc6a\ud835\udc6b\ud835\udc6c\ud835\udc6d\ud835\udc6e\ud835\udc6f\ud835\udc70\ud835\udc71\ud835\udc72\ud835\udc73\ud835\udc74\ud835\udc75\ud835\udc76\ud835\udc78\ud835\udc79\ud835\udc7a\ud835\udc7b\ud835\udc7c\ud835\udc7d\ud835\udc7e\ud835\udc7f\ud835\udc80\ud835\udc81"

  gothic:
    abc: "\ud835\udd1e\ud835\udd1f\ud835\udd20\ud835\udd21\ud835\udd22\ud835\udd23\ud835\udd24\ud835\udd25\ud835\udd26\ud835\udd27\ud835\udd28\ud835\udd29\ud835\udd2a\ud835\udd2b\ud835\udd2c\ud835\udd2e\ud835\udd2f\ud835\udd30\ud835\udd31\ud835\udd32\ud835\udd33\ud835\udd34\ud835\udd35\ud835\udd36\ud835\udd37"
    ABC: "\ud835\udd04\ud835\udd05\u212d\ud835\udd07\ud835\udd08\ud835\udd09\ud835\udd0a\u210c\u2111\ud835\udd0d\ud835\udd0e\ud835\udd0f\ud835\udd10\ud835\udd11\ud835\udd12\ud835\udd14\u211c\ud835\udd16\ud835\udd17\ud835\udd18\ud835\udd19\ud835\udd1a\ud835\udd1b\ud835\udd1c\u2128"
    digits: "\ud835\udfce\ud835\udfcf\ud835\udfd0\ud835\udfd1\ud835\udfd2\ud835\udfd3\ud835\udfd4\ud835\udfd5\ud835\udfd6\ud835\udfd7"

  bold_gothic:
    abc: "\ud835\udd86\ud835\udd87\ud835\udd88\ud835\udd89\ud835\udd8a\ud835\udd8b\ud835\udd8c\ud835\udd8d\ud835\udd8e\ud835\udd8f\ud835\udd90\ud835\udd91\ud835\udd92\ud835\udd93\ud835\udd94\ud835\udd96\ud835\udd97\ud835\udd98\ud835\udd99\ud835\udd9a\ud835\udd9b\ud835\udd9c\ud835\udd9d\ud835\udd9e\ud835\udd9f"
    ABC: "\ud835\udd6c\ud835\udd6d\ud835\udd6e\ud835\udd6f\ud835\udd70\ud835\udd71\ud835\udd72\ud835\udd73\ud835\udd74\ud835\udd75\ud835\udd76\ud835\udd77\ud835\udd78\ud835\udd79\ud835\udd7a\ud835\udd7c\ud835\udd7d\ud835\udd7e\ud835\udd7f\ud835\udd80\ud835\udd81\ud835\udd82\ud835\udd83\ud835\udd84\ud835\udd85"
    digits: "\ud835\udfce\ud835\udfcf\ud835\udfd0\ud835\udfd1\ud835\udfd2\ud835\udfd3\ud835\udfd4\ud835\udfd5\ud835\udfd6\ud835\udfd7"

  fixed_width:
    abc: "\uff41\u0020\uff42\u0020\uff43\u0020\uff44\u0020\uff45\u0020\uff46\u0020\uff47\u0020\uff48\u0020\uff49\u0020\uff4a\u0020\uff4b\u0020\uff4c\u0020\uff4d\u0020\uff4e\u0020\uff4f\u0020\uff50\u0020\uff51\u0020\uff52\u0020\uff53\u0020\uff54\u0020\uff55\u0020\uff56\u0020\uff57\u0020\uff58\u0020\uff59\u0020\uff5a"
    ABC: "\uff21\u0020\uff22\u0020\uff23\u0020\uff24\u0020\uff25\u0020\uff26\u0020\uff27\u0020\uff28\u0020\uff29\u0020\uff2a\u0020\uff2b\u0020\uff2c\u0020\uff2d\u0020\uff2e\u0020\uff2f\u0020\uff30\u0020\uff31\u0020\uff32\u0020\uff33\u0020\uff34\u0020\uff35\u0020\uff36\u0020\uff37\u0020\uff38\u0020\uff39\u0020\uff3a"
    digits: "\uff10\u0020\uff11\u0020\uff12\u0020\uff13\u0020\uff14\u0020\uff15\u0020\uff16\u0020\uff17\u0020\uff18\u0020\uff19"
    ",":"\uff0c"
    ".":"\uff0e"
    ":":"\uff1a"
    ";":"\uff1b"
    "!":"\uff01"
    "?":"\uff1f"
    "\"":"\uff02"
    "'":"\uff07"
#    "":"\uff40" # apostrophe slant weird
    "^":"\uff3e"
    "~":"\uff5e"
#    "":"\uffe3" # overscore
    "_":"\uff3f"
    "&":"\uff06"
    "@":"\uff20"
    "#":"\uff03"
    "%":"\uff05"
    "+":"\uff0b"
    "-":"\uff0d"
    "*":"\uff0a"
    "=":"\uff1d"
    "<":"\uff1c"
    ">":"\uff1e"
    "(":"\uff08"
    ")":"\uff09"
    "[":"\uff3b"
    "]":"\uff3d"
    "{":"\uff5b"
    "}":"\uff5d"
    "|":"\uff5c"
#    "":"\uffe4"
    "/":"\uff0f"
    "\\":"\uff3c"
#    "":"\uffe2"
    "$":"\uff04"
    "\u00a3":"\uffe1" # pound sign
    "\u00a2":"\uffe0" # cent sign
    "":"\uffe6"
    "\u00a5":"\uffe5" # yen sign

convert = (str, type='superscript')->
  out = []

  for i in [0...str.length]
    char = str[i]
    char_code = char.charCodeAt(0)
    if 97 <= char_code <= 122
      out.push replacements[type].abc[char_code - 97]
      continue

    if 65 <= char_code <= 90
      out.push replacements[type].ABC[char_code - 65]
      continue

    if 48 <= char_code <= 57
      out.push replacements[type].digits[char_code - 48]
      continue

    if replacements[type][char]
      out.push replacements[type][char]
      continue

    out.push char

  return out.join ''


escapeHTML = (str='')->
  out = (str || '').toString().replace /([&<>"'])/g, (z, a)->
    return escapeHTML.replacements[a]
  return out

escapeHTML.replacements =
  '&': '&amp;'
  '<': '&lt;'
  '>': '&gt;'
  '"': '&quot;'
  '\'': '&#39;'


window.onload = ()->

  $input = document.querySelector '#input'
  $output = document.querySelector '#output'
  $output_wrapper = document.querySelector '#output-wrapper'
  
  $input.focus()

  history_timeout = setTimeout ->, 1

  applyConversion = ()->

    input = $input.value

    output = escapeHTML convert input

    $output.innerHTML = output


    # throttle url change until a full second after they've stopped typing since it lags
    clearTimeout history_timeout
    history_timeout = setTimeout ->
      history.replaceState { input: input }, 'aa', "?#{input}"
    , 1000

    return

  resizeTextarea = ()->
    $input.style.height = 'auto'
    $input.style.height = $input.scrollHeight + 'px'
    $output_wrapper.style.marginBottom = ($input.clientHeight + resizeTextarea.extra_margin) + 'px'
    return

  resizeTextarea.extra_margin = 32

  applyConversion()
  resizeTextarea()

  $input.addEventListener 'keyup', applyConversion
  $input.addEventListener 'keyup', resizeTextarea

  document.querySelector('#select-all').addEventListener 'click', (e)->
    e.stopPropagation()
    e.preventDefault()

    selectAllOnTextarea $input

#    $input.focus()
#    $input.select 0, Infinity
    return false


  copy_btn = document.querySelector '#copy'
  copy_btn.addEventListener 'click', (e)->
    e.preventDefault()
    e.stopPropagation()

    copyToClipboard convert $input.value

    return false


selectAllOnTextarea = (el)->

  el.focus()

  if /ipad|iphone/i.test navigator.userAgent
    range = document.createRange()
    range.selectNodeContents el
    selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange range
    el.setSelectionRange 0, Infinity

  el.select()

  return

copyToClipboard = (str)->
  textarea = document.createElement 'textarea'
  textarea.value = str
  textarea.style.position = 'absolute'
  textarea.style.top = '-99999px'
  document.body.appendChild textarea

  if document.getSelection().rangeCount > 0
    old_selection = document.getSelection().getRangeAt 0

  selectAllOnTextarea textarea

  document.execCommand 'copy'
  document.body.removeChild textarea

  if old_selection
    document.getSelection().removeAllRanges()
    document.getSelection().addRange old_selection

  return

